<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-03 16:17:50
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2024-01-15 15:32:04
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\login\index.vue
 * @Description: 
-->
<template>
	<div class="login">
		<div class="login-headers">
			<div class="login-header-name">智慧社区</div>
			<div class="login-header-info">政苑社区民主自治网络空间！</div>
		</div>
		<!-- <div class="login-footer"></div> -->
		<div class="login-form md-small-size-100">
			<form novalidate class="" @submit.prevent="validateUser">
				<md-card class="md-layout-item form-md-card">
					<!-- <md-card-header>
						<div class="md-title">账号登录</div>
					</md-card-header> -->
					<div class="login-card-cont">
						<!-- <div class="login-card-bg">
							<img src="@/assets/img/login/bg-3.png" class="card-bg" alt="" srcset="">
						</div> -->
						<div class="login-card-form">
							<md-card-content>
								<img src="@/assets/img/login/logo.png" class="login-logo" alt="" srcset="">
								<div class="md-title md-display-2 login-form-title">欢迎登录！</div>
								<div class="md-layout md-gutter mt-10">
									<div class="md-layout-item md-small-size-100">
										<md-field :class="getValidationClass('firstName')">
											<label for="first-name"><i class="el-icon-user mr-5"></i>用户名</label>
											<md-input name="first-name" id="first-name" autocomplete="off"
												v-model="form.firstName" :disabled="sending" />
											<span class="md-error" v-if="!$v.form.firstName.required">请输入账号</span>
											<span class="md-error" v-else-if="!$v.form.firstName.minlength">账号格式不正确</span>
										</md-field>
									</div>
									<!-- <div class="md-layout-item md-small-size-100">
								<md-field :class="getValidationClass('lastName')">
									<label for="last-name">Last Name</label>
									<md-input name="last-name" id="last-name" autocomplete="family-name"
										v-model="form.lastName" :disabled="sending" />
									<span class="md-error" v-if="!$v.form.lastName.required">The last name is
										required</span>
									<span class="md-error" v-else-if="!$v.form.lastName.minlength">Invalid last name</span>
								</md-field>
							</div> -->
								</div>
								<!-- <div class="md-layout md-gutter">
							<div class="md-layout-item md-small-size-100">
								<md-field :class="getValidationClass('gender')">
									<label for="gender">Gender</label>
									<md-select name="gender" id="gender" v-model="form.gender" md-dense :disabled="sending">
										<md-option></md-option>
										<md-option value="M">M</md-option>
										<md-option value="F">F</md-option>
									</md-select>
									<span class="md-error">The gender is required</span>
								</md-field>
							</div>

							<div class="md-layout-item md-small-size-100">
								<md-field :class="getValidationClass('age')">
									<label for="age">Age</label>
									<md-input type="number" id="age" name="age" autocomplete="age" v-model="form.age"
										:disabled="sending" />
									<span class="md-error" v-if="!$v.form.age.required">The age is required</span>
									<span class="md-error" v-else-if="!$v.form.age.maxlength">Invalid age</span>
								</md-field>
							</div>
						</div> -->
								<md-field :class="getValidationClass('password')">
									<label for="password"><i class="el-icon-lock mr-5"></i>密码</label>
									<md-input type="password" name="password" id="password" autocomplete="password"
										v-model="form.password" :md-open-on-focus="true" :disabled="sending" />
									<span class="md-error" v-if="!$v.form.password.required">请输入账号密码</span>
									<span class="md-error" v-else-if="!$v.form.password.minlength">密码格式不正确</span>
								</md-field>
								<md-field v-if="currentErrorNum >= 2" :class="getValidationClass('verCode')">
									<label for="verCode">验证码</label>
									<md-input type="text" name="verCode" id="verCode" ao v-model="form.verCode" />
									<el-image @click="getVerCode" class="vercode-image" :src="verCodeImg"
										fit="contain"></el-image>
									<span class="md-error" v-if="!$v.form.verCode.required">请输入验证码</span>
									<span class="md-error" v-else-if="!$v.form.verCode.minlength">密码格式不正确</span>
								</md-field>
								<div>
									<div class="text-r forget-pas" @click="forgetPas">忘记密码?</div>
								</div>
							</md-card-content>
							<div class="progress-bar">
								<md-progress-bar md-mode="indeterminate" v-if="sending" />
							</div>

							<md-card-actions>
								<md-button type="submit" class="md-success md-display-2" block :disabled="sending">登
									录</md-button>
							</md-card-actions>
							<!-- <div class="mt-25 text-c">
								<el-checkbox class="forget-pas" v-model="checked">记住账号登录</el-checkbox>
							</div> -->
						</div>
					</div>
				</md-card>

				<md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar>
			</form>
		</div>
		<el-dialog title="忘记密码" :visible.sync="updatePasVisible" width="500px">
			<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="手机号" prop="mobile">
					<el-input type="text" v-model="ruleForm.mobile" placeholder="请输入手机号" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="smsCode">
					<el-input type="text" style="width: 230px;" v-model="ruleForm.smsCode" placeholder="请输入验证码"
						autocomplete="off"></el-input>
					<el-button class="ml-15" style="width: 112px;" :disabled="isSend" type="success" @click="sendCode">{{
						btnText }}</el-button>
				</el-form-item>
				<el-form-item label="新密码" prop="newPassword">
					<el-input type="password" v-model="ruleForm.newPassword" placeholder="请输入新密码"
						autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="checkPass">
					<el-input type="password" v-model="ruleForm.checkPass" placeholder="请输入确认密码"
						autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="UpdatePasDialogClose">取 消</el-button>
				<el-button type="success" @click="confirmPas">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
  
<script>
import { validationMixin } from 'vuelidate'
import {
	required,
	email,
	minLength,
	maxLength
} from 'vuelidate/lib/validators'
import * as API from '@/api/login.js'

const validationVerCode = {
	required: false,
	maxLength: minLength(4)
}
export default {
	name: 'FormValidation',
	mixins: [validationMixin],
	data () {
		const validatePhone = (rule, value, callback) => {
			if (!value) {
				callback(new Error('手机号不能为空'))
			}
			if (!/^1[35789]\d{9}$/.test(value)) {
				callback(new Error('手机号格式不正确'))
			}
			callback()
		}
		var validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.ruleForm.checkPass !== '') {
					this.$refs.ruleForm.validateField('checkPass');
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.ruleForm.newPassword) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			btnText: '发送验证码',
			ruleForm: {
				mobile: '',
				smsCode: '',
				newPassword: '',
				checkPass: ''
			},
			verifyKey: '',
			rules: {
				mobile: [
					{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					},
					{
						required: true,
						validator: validatePhone,
						trigger: 'blur'
					}
				],
				smsCode: [
					{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					},
				],
				newPassword: [
					{ required: true, validator: validatePass, trigger: 'blur', }
				],
				checkPass: [
					{ required: true, validator: validatePass2, trigger: 'blur' }
				]
			},
			updatePasVisible: false,
			checked: false,
			form: {
				firstName: '',
				password: '',
				verCode: ''
			},
			userSaved: false,
			sending: false,
			lastUser: null,
			currentErrorNum: 0,
			verCodeImg: '',
			verKey: '',
			isSend: false,
		}
	},
	validations: {
		form: {
			firstName: {
				required,
				minLength: minLength(3)
			},
			password: {
				required,
				maxLength: minLength(6)
			},
			verCode: validationVerCode
		}
	},
	created () {
		const name = localStorage.getItem('userName') || ''
		const pas = localStorage.getItem('userPas') || ''
		if (name && pas) {
			this.form.firstName = name
			this.form.password = pas
			this.checked = true
		}
	},
	methods: {
		sendCode () {
			if (this.isSend) return
			this.$refs.ruleForm.validateField(['mobile'], async (errMsg) => {
				if (!errMsg) {
					const data = await API.getSendCode({
						mobile: this.ruleForm.mobile
					})
					if (data.code === 200) {
						this.$notify.success({
							title: '提示',
							message: '已发送验证码，请注意查收！'
						})
						this.verifyKey = data.data.key || ''
						this.isSend = true
						let time = 60
						const interTime = setInterval(() => {
							time--
							this.btnText = time + '秒'
							if (time === 0) {
								this.btnText = '重新发送'
								this.isSend = false
								clearInterval(interTime)
							}
						}, 1000)
					}
				}
			})
		},
		forgetPas () {
			this.updatePasVisible = true
		},
		UpdatePasDialogClose () {
			this.ruleForm = {
				mobile: '',
				smsCode: '',
				newPassword: '',
				checkPass: ''
			}
			this.$refs.ruleForm.clearValidate()
			this.updatePasVisible = false
		},
		async confirmPas () {
			this.$refs.ruleForm.validate(async vaild => {
				if (vaild) {
					const { mobile, smsCode, newPassword } = this.ruleForm
					const data = await API.forgetPassword({
						mobile,
						smsCode,
						newPassword,
						smsKey: this.verifyKey
					})
					if (data.code === 200) {
						this.$notify.success({
							title: '提示',
							message: '密码修改成功！'
						})
						this.UpdatePasDialogClose()
					}
				}
			})
		},
		getValidationClass (fieldName) {
			const field = this.$v.form[fieldName]
			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		async getVerCode () {
			const data = await API.getVerCode()

			if (data.code === 200) {
				this.verCodeImg = data.data.image
				this.verKey = data.data.key
			}
		},
		clearForm () {
			this.$v.$reset()
			this.form.firstName = null
			this.form.password = null
		},
		saveUserInfo (name, pas) {
			localStorage.setItem('userName', name)
			localStorage.setItem('userPas', pas)
		},
		async saveUser () {
			this.sending = true
			try {
				const params = {
					password: this.form.password,
					username: this.form.firstName,
				}
				if (this.currentErrorNum >= 2) {
					params.verCode = this.form.verCode
					params.verKey = this.verKey
				}
				const data = await API.login({
					...params
				})
				if (data.code === 200 && data.data) {
					if (this.checked) this.saveUserInfo(this.form.firstName, this.form.password)

					this.lastUser = `${this.form.firstName}`
					this.userSaved = true
					this.clearForm()
					const { token, tokenHead } = data.data
					this.$store.dispatch('setToken', tokenHead + token)
					this.$router.push('/')
				} else {
					this.currentErrorNum = data.data.errNums
					if (this.currentErrorNum >= 2) {
						validationVerCode.required = required
						this.getVerCode()

					}
					// this.$notify.error({
					// 	title: '提示',
					// 	message: data.message || '登录失败，请稍后重试'
					// });
					// this.$message.error(data.message || '登录失败，请稍后重试')
					// this.$notify({
					// 	message: data.message || '登录失败，请稍后重试',
					// 	icon: "add_alert",
					// 	horizontalAlign: 'center',
					// 	verticalAlign: 'top',
					// 	type: 'warning',
					// })
				}
				this.sending = false
			} catch (error) {
				this.sending = false
				console.error('API.login: ', error)
			}
			// Instead of this timeout, here you can call your API
			// window.setTimeout(() => {
			// 	this.lastUser = `${this.form.firstName} ${this.form.lastName}`
			// 	this.userSaved = true
			// 	this.sending = false
			// 	this.clearForm()
			// }, 1500)
		},
		validateUser () {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				this.saveUser()
			}
		}
	}
}
</script>
  
<style lang="scss" scoped>
.login {
	width: 100vw;
	height: 100vh;
	box-sizing: border-box;
	background: url(../../assets/img/login/bg.jpeg) no-repeat center center;
	background-size: 100%;

	.login-logo {
		width: 60px;
	}

	.login-headers {
		position: absolute;
		left: 12%;
		top: 24%;
		color: #fff;

		.login-header-name {
			font-family: 'AlimamaShuHeiTi1';
			font-size: 52px;
			line-height: 55px;
			font-weight: bold;
		}

		.login-header-info {
			font-family: 'AlimamaShuHeiTi2';
			font-size: 30px;
			line-height: 50px;
			font-weight: bold;
		}
	}

	&-header {
		position: relative;
		height: 50vh;
		line-height: 50vh;
		color: #Fff;
		// background: linear-gradient(87deg, #2dce89, #2dcecc) !important;
		// background: url(../../assets/img/login/bg-2.png) no-repeat center bottom;
		// background-size: 130%;
		// font-size: 56px;
		text-align: center;
	}

	&-footer {
		position: relative;
		height: 50vh;
		line-height: 50vh;
		// background: url(../../assets/img/login/bg-1.png) no-repeat center bottom;
		// background-size: 300%;
	}

	.forget-pas {
		cursor: pointer;
		color: #fff;

		&:hover {
			opacity: .9;
		}
	}

	::v-deep .el-checkbox__inner {
		position: relative;
		top: 0px;
		background-color: rgba(0, 0, 0, 0);
	}

	&-form {
		position: absolute;
		z-index: 99;
		top: 20vh;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 100vw;

		.form-md-card {
			position: absolute;
			right: 15%;
			padding: 10px 0 20px;
			// margin: 0 auto;
			width: 450px;
			background-color: rgba(0, 0, 0, 0.5);
			color: #fff;
			// padding: 7% 5%;
			// padding: 140px 120px 140px 70px;

			.login-card-cont {
				display: flex;
			}

			.login-card-form {
				flex: 1;
				margin-left: 20px;
			}

			.login-card-bg {
				flex: 1;
				display: flex;
				align-items: center;
				min-width: 140px;
			}

			.card-bg {
				width: 100%;
			}
		}
	}

	.progress-bar {
		padding: 0 20px;
		height: 5px;
	}

	.login-form-title {
		margin-top: 8px;
		font-family: PingFang SC;
		// font-size: 40px;
		// font-weight: 500;
		// line-height: 20px;
		// text-align: center;
		letter-spacing: 0px;
		font-weight: bold;
		/* 深绿色纯色按钮 */
		color: #36AB64;
	}

	::v-deep .md-card-header {
		background-color: #fff;
	}

	::v-deep .md-card-actions {
		border-top: none;
	}

	::v-deep .md-card-content {
		padding-bottom: 5px;
	}

	.md-success {
		width: 100%;
		line-height: 40px;
		font-size: 16px;
	}

	.vercode-image {
		width: 100px;
		cursor: pointer;
	}
}

::v-deep .md-toggle-password {
	display: none;
}

::v-deep .md-input {
	color: #fff !important;
	-webkit-text-fill-color: #f2f2f2 !important;
}

::v-deep .md-field label {
	color: #fff !important;
	font-size: 16px;
}
</style>