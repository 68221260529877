/*
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-14 16:30:16
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-08 18:14:57
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\api\login.js
 * @Description: 
 */
import Http from '@/utils/http.js'

export const login = (params) => {
	return Http({
		url: '/admin/login',
		method: 'post',
		data: params
	})
}

export const getVerCode = () => {
	return Http({
		url: '/admin/captcha',
		method: 'get'
	})
}

export const getSendCode = (params) => {
	return Http({
		url: '/admin/sendSmsCaptcha',
		method: 'post',
		params: params
	})
}

export const forgetPassword = (params) => {
	return Http({
		url: '/admin/forgetPassword',
		method: 'post',
		data: params
	})
}